import { ValidationMixin } from '@/shared/mixins';
import { Component } from 'vue-property-decorator';

@Component({})
export default class FichiersPnceeValidator extends ValidationMixin {

    public dateRules = [
        (v: string | any) => this.required(v) || 'La date est obligatoire',
    ];

    protected nomDepotDefaultRules = [
        (v: string | any) => this.required(v) || 'Le Nom du dépôt est obligatoire',
    ];

}
