import { CriteriaBase } from '@/shared/models';

/**
 * Modèle pour les critères de recherche des utilisateurs.
 * */
export class FichiersPnceeCriteria extends CriteriaBase {

    /**
     * le nom du dépôt.
     */
    public nomDepot: string = '';

    // Date début EngagementTravaux.
    public dateDebutEngagementTravaux: string = '';

    // Date fin EngagementTravaux.
    public dateFinEngagementTravaux: string = '';

    // Date début FinalisationTravaux.
    public dateDebutAchevementTravaux: string = '';

    // Date fin FinalisationTravaux.
    public dateFinAchevementTravaux: string = '';

    // Les échantillons séléctionnés
    public echantillonsSelectedId: number[] = [];

    // Si l'origine est market place ou non.
    public estMarketPlace: boolean = false;

}