import { Component, Mixins } from 'vue-property-decorator';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import template from './FichiersPncee.Template.vue';
import FichiersPnceeValidator from './FichiersPncee.Validator';
import { ApiService } from '@/services/base/ApiService';
import { ApiHelper } from '@/services/ApiHelper';
import { GrilleMixin } from '@/shared/mixins/Grille.mixin';
import { Getter, Action } from 'vuex-class';
import { ReferentielStoreMethods, getterKeyReferentiel } from '@/store/modules/referentiel/ReferentielStore';
import { ValeurReferentielle, TypeValeurReferentielle } from '@/shared/models';
import { FileContentResult } from '@/shared/models';
import { FichiersPnceeCriteria } from './models/FichiersPnceeCriteria.model';
import CeeDatePicker from '@/components/CeeDatePicker.vue';
import { Profils } from '@/shared/enums';
import { isBoolean } from 'lodash';
import { Echantillon } from '@/models/Echantillon.model';
import CeeAutocomplete from '@/components/CeeAutocomplete.vue';

@Component({
    ...template,
    name: 'FichiersPncee',
    components: {
        CeeDatePicker,
        ValidationProvider,
        ValidationObserver,
        CeeAutocomplete
    },
})
export default class FichiersPncee extends Mixins(FichiersPnceeValidator, GrilleMixin) {
    public $refs!: {
        form: HTMLFormElement,
    };

    // Loader.
    public loadingCSVImportEmmy: boolean = false;
    public loadingCSVRecapEmmy: boolean = false;
    public loadingAttributionDepot: boolean = false;
    public loadingEchantillons:boolean = false;

    // Echantillons
    public listeEchantillons: Echantillon[] = [];
    public echantillonsLibelles: string[] = [];
    public echantillonsSelected: string[] = [];

    // Critères de recherches avancés.
    public criteresAvances: FichiersPnceeCriteria = new FichiersPnceeCriteria();
    // Permet de faire en sorte que le template ait accès à l'énumération.
    public Profils = Profils;

    @Getter(getterKeyReferentiel(TypeValeurReferentielle.TemplateTypeChamp))
    public typesChamps: ValeurReferentielle[];
    @Action(ReferentielStoreMethods.GET_VALEURS_REFERENTIELLES)
    public getValeursReferentielles: (type: TypeValeurReferentielle) => Promise<ValeurReferentielle[]>;

    // Permet de savoir si c'est un administrateur informatique.
    public estAdministrateurInformatique: boolean = false;
    /**
     * Règles spécifiques au noms de dépôt.
     */
    public nomDepotErrorMessages: string = '';

    /**
     * constructor.
     */
    constructor() {
        super();
    }
    /**
     * Hook qui se déclenche lorsque le composant est crée.
     */
    public created() {
        this.nomDepotErrorMessages = '';
        this.getAllEchantillonDeposable();
    }

    public importPnceeApiService = new ApiService<FileContentResult>('/pncee/genererFichierImportPnceeParCriteres');
    public importRecapitulatifApiService = new ApiService<FileContentResult>('/pncee/genererFichierTableauRecapitulatifEmmy');

    /**
     * Générer le fichier d'import EMMY des opérations.
     */
    public genererFichierImportEmmyOperations(): void {
        if (this.$refs.form.validate()) {
            this.loadingCSVImportEmmy = true;
            this.criteresAvances.echantillonsSelectedId = this.listeEchantillons.filter(x => this.echantillonsSelected.includes(x.libelle)).map(x => x.id);
            this.importPnceeApiService.getWhere(this.criteresAvances.queryString).then((reponse: any) => {
                // Vérifie qu'il y' a pas d'erreurs.
                if (reponse && reponse.data !== null && !(reponse.data.hasOwnProperty('isError') && isBoolean(reponse.data.isError))) {
                    if (reponse.data !== undefined && typeof reponse.data !== 'undefined') {
                        ApiHelper.createAndDownloadBlobFile(reponse.data as unknown as FileContentResult);
                    }
                }
            }).finally(() => this.loadingCSVImportEmmy = false);
        }
    }
    /**
     * Générer le fichier du tableau récapitulatif EMMY.
     */
    public genererFichierTableauRecapitulatifEMMY(): void {
        if (this.$refs.form.validate()) {
            this.loadingCSVRecapEmmy = true;

            this.importRecapitulatifApiService.getWhere(this.criteresAvances.queryString).then((reponse: any) => {
                // Vérifie qu'il y' a pas d'erreurs.
                if (reponse && reponse.data !== null && !(reponse.data.hasOwnProperty('isError') && isBoolean(reponse.data.isError))) {
                    if (reponse.data !== undefined && typeof reponse.data !== 'undefined') {
                        ApiHelper.createAndDownloadBlobFile(reponse.data as unknown as FileContentResult);
                    }
                }
            }).finally(() => {
                this.loadingCSVRecapEmmy = false;
                // On restaure.
                // this.nomDepotRules = this.nomDepotDefaultRules;
            });
        }
    }

    /**
     * Clear.
     */
    public clear(): void {
        this.$refs.form.reset();
        this.loadingCSVImportEmmy = false;
        this.loadingCSVRecapEmmy = false;
        this.loadingAttributionDepot = false;
    }

    public getAllEchantillonDeposable(): void {
        this.loadingEchantillons = true;
        this.$http.get(`/echantillon/obtenirEchantillonDeposable`)
            .then((result) => {
                const { data: { data: responseData } } = result as ({ data: { data: Echantillon[], isError: boolean, messages: any[] } });
                if (!!responseData && responseData.length > 0) {
                    this.listeEchantillons = responseData;
                    this.echantillonsLibelles = responseData.map(x => x.libelle);
                };
            }).finally(() => { this.loadingEchantillons = false; });
    }    
}

